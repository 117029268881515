<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row"  v-if="!loading">
              <div class="col-4">
                <h4>
                  <router-link :to="{name:'role'}">
                    <i class="fas fa-arrow-left mr-2"></i>
                  </router-link>
                  {{ role.name }}'s Permissions
                </h4>
              </div>
              <div class="col-8 text-right" v-if="checkIsAccessible('permission','edit')">
                <v-btn :loading="loading" @click="setDefaultPermission()" v-if="role.name != 'admin'" class="btn btn-primary ml-2 text-white">
                  <i class="fas fa-key"></i>
                  Set/reset default permission
                </v-btn>
                <v-btn :loading="loading" @click="goCustomRoleAndPermission" v-if="role.name != 'admin'" class="btn btn-primary ml-2 text-white">
                  <i class="fas fa-key"></i>
                  Manage custom permission
                </v-btn>

                <v-btn  @click="showPermissionList()" class="btn btn-primary ml-2 text-white">
                  <i class="fas fa-list"></i>
                  Permission list
                </v-btn>
                <v-btn  @click="assignPermission()" :loading="loading" class="btn btn-primary ml-2 text-white">
                  <i class="fas fa-edit"></i>
                  Update permission
                </v-btn>
              </div>
            </div>
            <div class="table-responsive mt-2">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              ></v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead class="text-left">
                <th>{{ (role.name) ? role.name.toUpperCase() : null }}</th>
                <th colspan="3"> ({{ role_permissions && role_permissions.length > 0 ? role_permissions.length : 0 }})
                  Assigned permissions
                </th>
                <th colspan="2">permission</th>
                </thead>
                <tbody class="text-left">
                <tr>
                  <th>
                    <v-checkbox v-if="role.name != 'admin' "
                                id="checkbox-1"
                                v-model="status"
                                @change="toggleRolePermission()"
                                name="checkbox-1"
                                label="Select all"
                    >
                    </v-checkbox>
                  </th>
                  <th class="w-20">Create</th>
                  <th class="w-20">Delete</th>
                  <th class="w-20">Edit</th>
                  <th class="w-20">List</th>
                  <th class="w-20">Show</th>
                </tr>
                <tr v-for="(permission_list, i) of permissions" :key="i">
                  <th>
                    {{ i }}
                  </th>
                  <td v-for="(permission, j) of permission_list" :key="j" v-if="i !== 'Custom'">
                    <label v-if="i =='other'">{{ permission }}</label>
                    <v-switch v-model="selected_permissions" :value="permission" dense></v-switch>
                  </td>
                  <td v-if="i === 'Custom'" colspan="5">
                    <v-row>
                      <v-col cols="3" v-for="(permission, j) of permission_list" :key="j">
                        <v-switch v-model="selected_permissions" :value="permission" :label="permission" dense></v-switch>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>


    </div>
    <create-and-update ref="create-permission" @update_list="updatePermissionList"></create-and-update>
  </v-app>
</template>
<script>
import PermissionService from "@/services/admin-user/permission/PermissionService";
import RoleService from "@/services/user/role/RoleService";
import CreateAndUpdate from "./CreateaAndUpdate"
import {mapGetters} from "vuex";

const roleService = new RoleService();
const permissionService = new PermissionService();

export default {
  name: "users",
  components: {CreateAndUpdate},
  data() {
    return {
      loading: false,
      selected_permissions: [],
      role_permissions: [],
      permissions: [],
      role: {},
      permission_name: null,
      total: null,
      status: false,
      perPage: null,
      total_available_permissions: 0
    };
  },
  computed: {
    role_id() {
      return this.$route.params.id;
    },
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.rolePermissions();
    this.getRole();
    this.getPermissions();
  },
  methods: {
    goCustomRoleAndPermission() {
      this.$router.push({name: 'user-custom-permission', params: {id:this.role.id}})
    },
    rolePermissions() {
      roleService.rolePermissions(this.role_id).then(response => {
        this.role_permissions = response.data;
        this.selected_permissions = [];
        if (this.role_permissions && this.role_permissions.length > 0) {
          this.role_permissions.forEach(element => {
            this.selected_permissions.push(element);
          });
        }
      });
    },

    getRole() {
      roleService.show(this.role_id).then(response => {
        this.role = response.data.role;
      });
    },

    getPermissions() {
      permissionService.getGroupedPermission().then(response => {
        this.permissions = response.data.permission_list;
        this.total_available_permissions = response.data.total_permissions;
        if (this.selected_permissions.length == this.total_available_permissions) {
          this.status = true
        }
      });
    },

    setDefaultPermission() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            roleService
                .setDefault(this.role_id)
                .then(response => {
                  this.$snotify.success("Assigned successfully");
                  this.rolePermissions();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },

    assignPermission() {
      roleService
          .assignPermission(this.role_id, {permissions: this.selected_permissions})
          .then(response => {
            this.user_roles = [];
            this.$snotify.success("Assigned Successfully");
            this.rolePermissions();
          });
    },

    showPermissionList() {
      this.$router.push({name: "permission-list"})
    },

    updatePermissionList() {
      this.getPermissions();
      this.getRole();
    },

    toggleRolePermission() {
      if (!this.status) {
        this.selected_permissions = [];
        this.assignPermission();
      } else {
        permissionService.all().then(response => {
          let permissions = response.data.data
          permissions.forEach(ele => {
            this.selected_permissions.push(ele.name)
          })
          this.assignPermission();
        })
      }
    }
  }
};
</script>

<style>
.w-20 {
  width: 200px;
}
</style>